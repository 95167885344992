.root {
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-primary-semi-bold);
  font-size: 0.8125rem;
  letter-spacing: -0.02692307692307692em;

  color: var(--palette-error-500);
  border: 1px solid currentColor;
  border-radius: 20px;
  padding: 1px var(--mini-unit);
  white-space: nowrap;
}
.colorReported {
  color: var(--palette-error-500);
}
.colorPending {
  color: #2C7B8C;
}
.colorWarning {
  color: var(--palette-warning-900);
}
.variantRegular {
}
.variantFilled {
  color: var(--palette-text-000);
}
.variantFilled.colorReported {
    background-color: var(--palette-error-500);
    border-color: var(--palette-error-500);
  }
.variantFilled.colorPending {
    background-color: #2C7B8C;
    border-color: #2C7B8C;
  }
.variantFilled.colorWarning {
    background-color: var(--palette-warning-100);
    border-color: var(--palette-warning-900);
    color: var(--palette-text-500);
  }
