.root {
  font-size: var(--font-size-2);
  font-weight: var(--font-weight-primary-bold);
  font-family: var(--font-family-primary);
  line-height: 1.14;
  letter-spacing: 0.014285714285714287em;
  border-radius: var(--round-corners);
  padding: 1px 3px 1px 3px;
  color: inherit;
}
.sizeSmall {
  font-size: var(--font-size-1);
  padding: 2px 3px;
  line-height: 1.33;
}
.text {
  color: var(--palette-text-000);
}
.colorInherit {
  background-color: currentColor;
}
.colorPrimary {
  background-color: #2C7B8C;
}
.colorGrey {
  background-color: var(--palette-grey-200);
  box-shadow: inset 0px 0px 0px 1px var(--palette-grey-600);
}
.colorGrey .text {
    color: var(--palette-grey-600);
  }
.colorDark {
  background-color: var(--palette-grey-600);
}
.colorError {
  background-color: var(--palette-error-700);
}
.colorEmphasis {
  background-color: var(--palette-grey-500);
}
.colorDefault {
  background-color: var(--palette-grey-500);
}
.colorAlert {
  background-color: var(--palette-grey-500);
}
